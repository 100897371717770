import React from 'react';
import CorporateGovernanceLayout from 'layouts/corporate-governance-layout';
import { GoBackButton, MobileHeading } from 'components/misc';

const CompanyFinancialInformation = ()=> {
  return (
    <CorporateGovernanceLayout>
      <GoBackButton path="/investors" name="Dla akcjonariuszy" />
      <MobileHeading heading="Dokumenty finansowe" />
      <p>Brak sprawozdań finansowych</p>
    </CorporateGovernanceLayout>
  );
};

export default CompanyFinancialInformation;
